export const privacyProperties = [
    'whatData',
    'country',
    'accessData',
    'securityMeasures',
    'privacyStatementURLen',
    'privacyStatementURLnl',
    'dpaType',
    'otherInfo'
]
